import PropTypes from 'prop-types';
import Image from '@activebrands/core-web/components/Image';
import Link from '@activebrands/core-web/components/Link';
import media from '@activebrands/core-web/config/media';
import { styled } from '@activebrands/core-web/libs/styletron';
import Button from 'components/buttons/Button';

const Wrapper = styled('div', {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
});

const ShortcutsWrapper = styled('div', {
    width: '80%',
    display: 'flex',
    [media.max['mobile.md']]: {
        flexDirection: 'column',
        width: '100%',
    },
});

const AccountShortcuts = ({ shortcuts = [] }) => (
    <Wrapper>
        <ShortcutsWrapper>
            {shortcuts.map((shortcut, index) => (
                <Button
                    $style={{
                        display: 'flex',
                        width: '100%',
                        margin: '4px',
                        justifyContent: 'center',
                        backgroundColor: 'var(--color-bg-account-shortcuts)',
                        border: '1px solid var(--color-border-account-shortcuts)',
                        height: '56px',
                        padding: '13px',
                        alignItems: 'center',
                    }}
                    as={Link}
                    key={index}
                    rel={shortcut.rel}
                    size="md"
                    to={shortcut.url}
                >
                    <div>
                        {shortcut.icon && (
                            <Image $style={{ marginRight: '8px', height: '16px', width: '16px' }} src={shortcut.icon} />
                        )}
                    </div>
                    <span>{shortcut.label}</span>
                </Button>
            ))}
        </ShortcutsWrapper>
    </Wrapper>
);

AccountShortcuts.propTypes = {
    shortcuts: PropTypes.shape({
        icon: PropTypes.string,
        label: PropTypes.string,
        url: PropTypes.string,
    }),
};

export default AccountShortcuts;
